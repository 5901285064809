<template>
  <v-card class="accounting-document d-flex flex-column" style="height: 100%;">
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew || fill_loading"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog"
                       v-if="month === (is_legacy ? current_month_legacy : current_month)"
          >
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-0 pb-0 px-0"
                 style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
      <div class="document-body pt-0 pb-1" style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
        <div class="px-4 pt-2 pb-0" style="flex: 1; display: flex; height: 100%;">
          <v-row style="height: 100%;">
            <v-col cols="12" sm="12" md="12" style="overflow: hidden; height: 100%;">
              <v-tabs class="custom-tabs without" color="success" style="height: 100%; " v-model="tab">
                <v-tab>
                  Основне
                </v-tab>
                <v-tab v-if="semi_auto">
                  Таблиця
                </v-tab>

                <v-tab-item class="pa-0 pt-3" style="height: 100%;">
                  <v-row class="py-2 dense-wrapper">
                    <v-col cols="12" md="6">
                      <date-component v-model="month" req label="Місяць" disabled/>
                    </v-col>
                    <v-col cols="12" md="6">
                      <date-component v-model="date" req label="Дата" disabled/>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-switch v-model="semi_auto" color="success" hide-details class="pt-2 mt-0"
                                :label="semi_auto ? 'Авто-перерахунок (включено)' : 'Авто-перерахунок (вимкнено)'"
                                @change="afterServiceChange"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-switch v-model="end_all_by_period" color="success" hide-details class="pt-2 mt-0"
                                :disabled="!semi_auto"
                                :label="end_all_by_period ? 'Зняти нарах. за період (включено)' : 'Зняти нарах. за період (вимкнено)'"
                      />
                    </v-col>
                    <v-col cols="12" md="6" v-if="semi_auto">
                      <date-component v-model="month_start" monthly
                                      label="Місяць перерах. з"
                                      :req="semi_auto"
                                      :class_="semi_auto && !month_start ? 'req-star' : ''"
                                      @afterSaveDate="afterServiceChange"
                      />
                    </v-col>
                    <v-col cols="12" md="6" v-if="semi_auto">
                      <date-component v-model="month_end" monthly
                                      label="Місяць перерах. по"
                                      :req="semi_auto"
                                      :class_="semi_auto && !month_end ? 'req-star' : ''"
                                      @afterSaveDate="afterServiceChange"
                      />
                    </v-col>
                    <v-col :cols="is_legacy ? 12 : 6">
                      <v-select :items="services" filled label="Послуга"
                                hide-details
                                v-model="service_id"
                                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                :class="service_id ? '' : 'req-star'"
                                color="grey"
                                @change="afterServiceChange"
                      />
                    </v-col>
                    <v-col cols="12" md="12" v-if="is_legacy">
                      <ACC_ContractorContract :value="contract_id" @autocompleteChange="contractChange" :person_hash="person_hash"
                                              :contractor_name="flat_name"
                                              :class="contract_id ? '' : 'req-star'" color="grey"/>
                    </v-col>
                    <v-col cols="6" class="d-flex" v-if="is_legacy">
                      <v-switch v-model="tax_enable" class="mr-4"
                                label="Враховувати ПДВ" color="success" hide-details
                                @change="changeTaxEnable"
                      />
                      <v-switch v-model="tax_in_sum" :disabled="!tax_enable"
                                v-if="is_legacy" label="ПВД в ціні" color="success"
                                hide-details @change="changeTaxInSum"
                      />
                    </v-col>
                    <v-col cols="6" v-if="is_legacy">
                      <v-select :items="tax_select" filled label="Ставка ПДВ"
                                hide-details
                                v-model="tax_id"
                                color="grey"
                                @change="changeTaxInSum"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field type="text" hide-details filled
                                    :label="semi_auto ? 'Сума (автоматично)' : 'Сума'"
                                    v-model.number="sum" :disabled="semi_auto"
                                    required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                    :class="sum ? '' : 'req-star'"
                                    color="grey"
                                    v-decimal
                                    @change="changeSum"
                      />
                    </v-col>
                    <v-col cols="12" md="6" v-if="is_legacy">
                      <v-text-field type="text" hide-details filled label="ПДВ"
                                    v-model.number="tax"
                                    color="grey"
                                    v-decimal
                                    @change="changeTax"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea filled hide-details rows="2" color="grey" v-model="comment" label="Коментар до перерахунку"/>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item class="pa-0 pt-1" style="height: 100%;" v-if="semi_auto">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="secondary mr-2" :disabled="!document_state.header_ready"
                           :loading="fill_loading"
                           @click.stop="fillTable"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Заповнити
                    </v-btn>
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="success mr-2" :disabled="!document_state.header_ready"
                           :loading="fill_loading"
                           @click.stop="addRecalculationDetailRow"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="error lighten-1" :disabled="!document_state.header_ready"
                           :loading="fill_loading"
                           @click.stop="clearDialogRecalculationDetails"
                    >
                      <v-icon small left>mdi-close</v-icon>
                      Очистити
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body" style="max-height: 60vh; overflow: auto;">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px">
                            Місяць
                          </div>
                          <div class="document-table-col" style="padding: 0 !important;"
                               :style="showCounters ? 'flex: 0 0 360px;' : 'flex: 0 0 180px;'"
                          >
                            <div style="background-color: #fdfdfd !important;">
                              <div style="border-bottom: 1px solid #e8e8e8; padding: 4px; text-align: center">Було</div>
                              <div style="display: flex">
                                <div style="flex: 0 0 100px; border-right: 1px solid #e8e8e8; padding: 4px; text-align: center">
                                  {{ showCounters ? 'Нарах. по нормі' : 'Нараховано' }}
                                </div>
                                <div style="flex: 0 0 100px; border-right: 1px solid #e8e8e8; padding: 4px; text-align: center" v-if="showCounters">
                                  Нарах. по ліч.
                                </div>
                                <div style="flex: 0 0 80px; border-right: 1px solid #e8e8e8; padding: 4px; text-align: center">
                                  {{ showCounters ? 'Об’єм по нормі' : volume_column_name }}
                                </div>
                                <div style="flex: 0 0 80px; padding: 4px; text-align: center" v-if="showCounters">Об’єм по ліч.</div>
                              </div>
                            </div>
                          </div>
                          <div class="document-table-col" style="padding: 0 !important;"
                               :style="showCounters ? 'flex: 0 0 360px;' : 'flex: 0 0 180px;'"
                          >
                            <div>
                              <div style="border-bottom: 1px solid #e8e8e8; padding: 4px; text-align: center">Стало</div>
                              <div style="display: flex">
                                <div style="flex: 0 0 100px; border-right: 1px solid #e8e8e8; padding: 4px; text-align: center">
                                  {{ showCounters ? 'Нарах. по нормі' : 'Нараховано' }}
                                </div>
                                <div style="flex: 0 0 100px; border-right: 1px solid #e8e8e8; padding: 4px; text-align: center" v-if="showCounters">
                                  Нарах. по ліч.
                                </div>
                                <div style="flex: 0 0 80px; border-right: 1px solid #e8e8e8; padding: 4px; text-align: center">
                                  {{ showCounters ? 'Об’єм по нормі' : volume_column_name }}
                                </div>
                                <div style="flex: 0 0 80px; padding: 4px; text-align: center" v-if="showCounters">Об’єм по ліч.</div>
                              </div>
                            </div>
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px; text-align: center">
                            Перерахунок
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in recalculation_details"
                           :key="`charge-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ recalculation_details.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteRecalculationDetailRow(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 110px; display: flex">
                          <date_input :row_num="charge.row_num" v-model="charge.month"
                                      :required="true" small transparent month_type/>
                        </div>
                        <div class="document-table-col"
                             style="flex: 0 0 100px; display: flex; background-color: #fdfdfd;">
                          <number_input :row_num="charge.row_num" v-model="charge.sum_old"
                                        v-decimal small transparent :required="false"
                                        input_style="text-align: center; color: var(--v-error-base); font-weight: 500;"
                                        @userChange="onTableRowChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px; display: flex; background-color: #fdfdfd;" v-if="showCounters">
                          <number_input :row_num="charge.row_num" v-model="charge.sum_counter_old"
                                        v-decimal small transparent :required="false"
                                        input_style="text-align: center"
                                        @userChange="onTableRowChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 80px; display: flex; background-color: #fdfdfd;">
                          <number_input :row_num="charge.row_num" v-model="charge.volume_normative_old"
                                        v-decimal small transparent :required="false"
                                        input_style="text-align: center"
                                        @userChange="onTableRowChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 80px; display: flex; background-color: #fdfdfd;" v-if="showCounters">
                          <number_input :row_num="charge.row_num" v-model="charge.volume_counter_old"
                                        v-decimal small transparent :required="false"
                                        input_style="text-align: center"
                                        @userChange="onTableRowChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px; display: flex">
                          <number_input :row_num="charge.row_num" v-model="charge.sum_new"
                                        v-decimal small transparent :required="false"
                                        input_style="text-align: center; color: var(--v-success-darken2); font-weight: 500"
                                        @userChange="onTableRowChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px; display: flex" v-if="showCounters">
                          <number_input :row_num="charge.row_num" v-model="charge.sum_counter_new"
                                        v-decimal small transparent :required="false"
                                        input_style="text-align: center"
                                        @userChange="onTableRowChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 80px; display: flex">
                          <number_input :row_num="charge.row_num" v-model="charge.volume_normative_new"
                                        v-decimal small transparent :required="false"
                                        input_style="text-align: center"
                                        @userChange="onTableRowChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 80px; display: flex" v-if="showCounters">
                          <number_input :row_num="charge.row_num" v-model="charge.volume_counter_new"
                                        v-decimal small transparent :required="false"
                                        input_style="text-align: center"
                                        @userChange="onTableRowChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px; display: flex">
                          <number_input :row_num="charge.row_num" v-model="charge.sum_to"
                                        v-decimal small transparent :required="false"
                                        input_style="text-align: center; font-weight: 500"
                                        @userChange="onTableRowChange"
                          />
                        </div>
                      </div>
                      <div class="document-table-footer mt-2" style="bottom: -3px;">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 180px; padding-left: 10px; font-size: .8rem">
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px; font-size: .8rem; text-align: center">
                            {{ footer_total.sum_old | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px; font-size: .8rem; text-align: center" v-if="showCounters">
                            {{ footer_total.sum_counter_old | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 80px; font-size: .8rem; text-align: center">
                            {{ footer_total.volume_normative_old | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 80px; font-size: .8rem; text-align: center" v-if="showCounters">
                            {{ footer_total.volume_counter_old | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px; font-size: .8rem; text-align: center">
                            {{ footer_total.sum_new | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px; font-size: .8rem; text-align: center" v-if="showCounters">
                            {{ footer_total.sum_counter_new | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 80px; font-size: .8rem; text-align: center">
                            {{ footer_total.volume_normative_new | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 80px; font-size: .8rem; text-align: center" v-if="showCounters">
                            {{ footer_total.volume_counter_new | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px; font-size: .8rem; text-align: center">
                            {{ footer_total.sum_to | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>

              </v-tabs>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="updateService"
             :disabled="month !== current_month || fill_loading" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal" :disabled="fill_loading">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";
import {SELECT_SERVICES} from "@/store/actions/services";
import {QUESTION_SHOW} from "@/store/actions/question";

import recalculationAPI from "@/utils/axios/recalculation";
import {SELECT_TAXES_LIST_ELEMENTS} from "@/store/actions/accounting/list";
import {getCurrentDate, round_number, beginOfMonth} from "@/utils/icons";
import {GET_ACCOUNTING_GENERAL_SETTINGS} from "@/store/actions/organization";

import ModalComponentMixin from "@/mixins/modal_component";
import {maxBy} from "lodash";

const modalDeleteId = 'recalculation_modal_delete'

export default {
  name: "HWP_Modal_Recalculation",
  mixins: [ModalComponentMixin],
  components: {
    ACC_ContractorContract: () => import("@/components/accounting/autocomplite/modal/ACC_ContractorContract"),
    date_input: () => import("@/components/accounting/input/document_date_input"),
    number_input: () => import("@/components/accounting/input/document_number_input")
  },
  data() {
    return {
      flat_id: this.item.flat_id || undefined,
      service_id: this.item.service_id || 0,
      month: this.item.month,
      date: this.item.date,
      sum: this.item.sum || 0,
      comment: this.item.comment || '',
      is_legacy: this.item.is_legacy || false,
      tax_in_sum: this.item.tax_in_sum || false,
      tax_id: this.item.tax_type || null,
      tax_enable: this.item.tax_enable || false,
      tax: this.item.tax || 0,
      contract_id: this.item.contract_id || null,
      flat_name: this.item.flat_name || null,
      person_hash: this.item.person_hash || null,
      semi_auto: this.item.semi_auto || false,
      end_all_by_period: this.item.end_all_by_period || false,
      month_start: this.item.month_start || null,
      month_end: this.item.month_end || null,
      tab: 0,
      recalculation_details: [],
      document_state: {
        ready: false,
        header_ready: false,
        show: false,
        errors: []
      },
      footer_total: {
        sum_old: 0,
        sum_counter_old: 0,
        volume_normative_old: 0,
        volume_counter_old: 0,
        sum_new: 0,
        sum_counter_new: 0,
        volume_normative_new: 0,
        volume_counter_new: 0,
        sum_to: 0,
        count: 0
      },
      fill_loading: false
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll',
      current_month: 'getCurrentMonth',
      current_month_legacy: 'getCurrentMonthLegacy',
      tax_select: 'get_taxes_list_pdv_select',
      settings: 'getAccountingGeneralSettings',
      settings_home: 'getGeneralSettings',
    }),
    showCounters() {
      return this.settings_home?.work_place_counter?.value
    },
    volume_column_name() {
      return this.settings_home?.work_place_volume_column_name?.value || 'Об’єм'
    }
  },
  methods: {
    ...mapActions({
      fetchServices: SELECT_SERVICES,
      fetchTaxSelect: SELECT_TAXES_LIST_ELEMENTS,
      fetchAccountingSettings: GET_ACCOUNTING_GENERAL_SETTINGS
    }),
    round_number,
    contractChange(payload) {
      this.contract_id = payload?.value || null
    },
    changeSum() {
      this.$nextTick(() => {
        this.calcRow('sum')
        this.checkDocument(false, false)
      })
    },
    changeTax() {
      this.$nextTick(() => {
        this.calcRow('tax')
      })
    },
    changeTaxEnable(payload) {
      if (!payload) {
        this.tax_id = this.settings?.pdv_without?.value
      } else {
        this.tax_id = this.settings?.pdv_20?.value
      }
      this.$nextTick(() => {
        this.calcRow()
      })
    },
    changeTaxInSum() {
      this.$nextTick(() => {
        this.calcRow()
      })
    },
    getTaxTariff(tax_id) {
      if (!tax_id) return null
      const tariffs = {}
      const pdv_without = this.settings?.pdv_without?.value || 0
      const pdv_0 = this.settings?.pdv_0?.value || 0
      const pdv_5 = this.settings?.pdv_5?.value || 0
      const pdv_7 = this.settings?.pdv_7?.value || 0
      const pdv_9 = this.settings?.pdv_9?.value || 0
      const pdv_20 = this.settings?.pdv_20?.value || 0

      tariffs[pdv_without] = null
      tariffs[pdv_0] = 0
      tariffs[pdv_5] = 5
      tariffs[pdv_7] = 7
      tariffs[pdv_9] = 9
      tariffs[pdv_20] = 20

      return tariffs[tax_id] || null
    },
    calcRow(col = '') {
      const tax_tariff = this.getTaxTariff(this.tax_id)

      if (!this.sum) {
        this.sum = 0
        this.tax = 0
      }

      if (this.tax_enable) {
        if (col === 'tax') {
          if (this.tax_in_sum) {
            this.tax = this.round_number(this.sum / (100 / tax_tariff + 1), 2)
            // this.result = this.sum
          } else {
            this.tax = this.round_number(this.sum * tax_tariff / 100, 2)
            // this.result = this.round_number(this.sum + payload.tax, 2)
          }
          return;
        }
      } else {
        this.tax = 0
        this.result = this.sum
      }

      if (this.tax_enable) {
        if (this.tax_in_sum) {
          this.tax = this.round_number(this.sum / (100 / tax_tariff + 1), 2)
          // this.result = payload.sum
        } else {
          this.tax = this.round_number(this.sum * tax_tariff / 100, 2)
          // this.result = this.round_number(this.sum + this.tax, 2)
        }
      } else {
        this.tax = 0
        // this.result = this.sum
      }
    },
    closeModal(payload=null) {
      this.$emit('closeModal', payload)
      this.itemId = this.item.id
      this.flat_id = this.item.flat_id || null
      this.service_id = this.item.service_id
      this.date = this.item.date
      this.month = this.item.month
      this.sum = this.item.sum
      this.comment = this.item.comment
      this.is_legacy = this.item.is_legacy || false
      this.tax_in_sum = this.item.tax_in_sum || false
      this.tax_id = this.item.tax_type || null
      this.tax_enable = this.item.tax_enable || false
      this.tax = this.item.tax || 0
      this.contract_id = this.item.contract_id || null
      this.flat_name = this.item.flat_name
      this.person_hash = this.item.person_hash || null
      this.semi_auto = this.item.semi_auto || false
      this.end_all_by_period = this.item.end_all_by_period || false
      this.month_start = this.item.month_start || null
      this.month_end = this.item.month_end || null

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення перерахунку: ${this.getServiceName()}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    updateService() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        flat_id: this.flat_id,
        service_id: this.service_id,
        month: this.month,
        date: this.date,
        sum: this.sum,
        comment: this.comment,
        tax_in_sum: this.tax_in_sum || false,
        tax_type: this.tax_id || null,
        tax_enable: this.tax_enable || false,
        tax: this.tax || 0,
        contract_id: this.contract_id || null,
        is_legacy: this.is_legacy,
        semi_auto: this.semi_auto,
        end_all_by_period: this.end_all_by_period,
        month_start: this.month_start,
        month_end: this.month_end,
        table: this.recalculation_details
      }

      if (this.isNew) {
        recalculationAPI.create(payload)
          .then(response => response.data)
          .then(data => {
            if (data) {
              this.closeModal({ operation: 'fetch' })
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
      } else {
        payload['id'] = this.itemId
        recalculationAPI.update(payload)
          .then(response => response.data)
          .then(data => {
            if (data) {
              this.closeModal({ operation: 'fetch' })
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
      }
    },
    getServiceName() {
      if (this.service_id) {
        const ind = this.services.find(item => item.value === this.service_id)
        if (ind) {
          return ind.text
        }
      }
      return ''
    },
    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined && item[filter_row.condition] !== 0) {
              skip = item[key] !== null && item[key] !== undefined && item[key] !== 0
            } else {
              skip = true
            }
          } else {
            skip = item[key] !== null && item[key] !== undefined && item[key] !== 0;
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument(show=false, table=true) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'service_id': {id: 'service_id', text: 'Не заповнена послуга'},
        'month': {id: 'month', text: 'Не заповнений місяць обліку'},
        'date': {id: 'month', text: 'Не заповнена дата'},
      }

      if (this.semi_auto) {
        header['month_start'] = {id: 'month_start', text: 'Не заповнений місяць початку перерахунку'}
        header['month_end'] = {id: 'month_end', text: 'Не заповнений місяць завершення перерахунку'}
      } else {
        header['sum'] = {id: 'sum', text: 'Не заповнена сума перерахунку'}
      }

      const recalculation_details = {
        'month': {id: 'month', table: 'Таблиця', text: 'Не заповненй місяць перерахунку'},
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Основне', row_num: 0, text: header[key].text}
          )
        }
      })

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        this.checkDocumentTable(recalculation_details, 'Таблиця', 'recalculation_details')
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },
    fetch_data_by_id() {
      if (!this.isNew) {
        recalculationAPI.get_document_by_id(this.itemId)
            .then(response => response.data)
            .then(data => {
              this.tab = 0

              this.recalculation_details = data.map((i, idx) => {
                return Object.assign({}, i, {row_num: idx + 1})
              })

              this.$nextTick(() => {
                this.checkDocument(false, true)
                this.getTotals(false)
              })
            })
      }
    },
    getNewRowNum(table) {
      const max_row_item = maxBy(this[table], 'row_num')

      let max_row_num = 1
      if (max_row_item) {
        max_row_num = max_row_item.row_num + 1
      }
      return max_row_num
    },
    fillTable() {
      if (this.recalculation_details.length === 0) {
        this.fetchTableData()
      } else {
        const payload = {
          text: `Таблиця не порожня. Дані будуть перезаповнені. Продовжити?`,
          accept_button: true,
          id: 'fill_recalculation_details'
        }
        this.$store.dispatch(QUESTION_SHOW, payload)
      }
    },
    fetchTableData() {
      this.fill_loading = true
        const payload = {
          flat_id: this.flat_id,
          service_id: this.service_id,
          month: this.month,
          end_all_by_period: this.end_all_by_period,
          month_start: this.month_start,
          month_end: this.month_end,
          doc_id: this.itemId
        }
        recalculationAPI.fill_table(payload)
            .then(response => response.data)
            .then(data => {
              this.recalculation_details = data.map((i, idx) => {
                return Object.assign({}, i, {row_num: idx + 1})
              })
              this.getTotals()
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.fill_loading = false
            })
    },
    addRecalculationDetailRow() {
      this.recalculation_details.push(
          {
            month: beginOfMonth(getCurrentDate()),
            sum_old: 0,
            sum_counter_old: 0,
            sum_counter_1_old: 0,
            sum_counter_2_old: 0,
            sum_new: 0,
            sum_counter_new: 0,
            sum_counter_1_new: 0,
            sum_counter_2_new: 0,
            sum_to: 0,
            volume_normative_old: 0,
            volume_counter_old: 0,
            volume_counter_1_old: 0,
            volume_counter_2_old: 0,
            volume_normative_new: 0,
            volume_counter_new: 0,
            volume_counter_1_new: 0,
            volume_counter_2_new: 0,
            row_num: this.getNewRowNum('recalculation_details'),
          }
      )
    },
    deleteRecalculationDetailRow(payload) {
      this.recalculation_details.splice(this.recalculation_details.indexOf(payload), 1)
      this.getTotals()
    },
    clearRecalculationDetails() {
      this.recalculation_details = []
      this.getTotals()
    },
    clearDialogRecalculationDetails() {
      const payload = {
        text: `Очистити таблицю?`,
        accept_button: true,
        id: 'clear_recalculation_details'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    afterServiceChange() {
      this.$nextTick(() => {
        this.checkDocument(false, false)
      })
    },
    onTableRowChange(payload) {
      const row = this.recalculation_details.find(i => i.row_num === payload.row_num)
      if (!row) return

      this.calcDetailRow(row)
    },
    calcDetailRow(row) {
      row.sum_to = -((row.sum_old + row.sum_counter_old) - (row.sum_new + row.sum_counter_new))

      this.$nextTick(() => {
        this.getTotals()
      })
    },
    getTotals(change_sum=true) {
      this.footer_total = this.recalculation_details.reduce((acc, item) => {
        acc.count += 1
        acc.sum_old += item.sum_old || 0
        acc.sum_counter_old += item.sum_counter_old || 0
        acc.volume_normative_old += item.volume_normative_old || 0
        acc.volume_counter_old += item.volume_counter_old || 0
        acc.sum_new += item.sum_new || 0
        acc.sum_counter_new += item.sum_counter_new || 0
        acc.volume_normative_new += item.volume_normative_new || 0
        acc.volume_counter_new += item.volume_counter_new || 0
        acc.sum_to += item.sum_to || 0
        return acc
      }, {
        count: 0, sum_old: 0, sum_counter_old: 0, volume_normative_old: 0, volume_counter_old: 0,
        sum_new: 0, sum_counter_new: 0, volume_normative_new: 0, volume_counter_new: 0,
        sum_to: 0,
      })

      if (change_sum) {
        const sum_total = -((this.footer_total.sum_old + this.footer_total.sum_counter_old) -
            (this.footer_total.sum_new + this.footer_total.sum_counter_new))
        this.sum = this.round_number(sum_total, 2)
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.flat_id = payload.flat_id
              this.service_id = payload.service_id
              this.month = payload.month
              this.date = payload.date
              this.sum = payload.sum
              this.comment = payload.comment
              this.tax_in_sum = payload.tax_in_sum || false
              this.tax_id = payload.tax_type || false
              this.tax_enable = payload.tax_enable || false
              this.tax = payload.tax || 0
              this.contract_id = payload.contract_id
              this.flat_name = payload.flat_name
              this.person_hash = payload.person_hash
              this.semi_auto = payload.semi_auto
              this.end_all_by_period = payload.end_all_by_period
              this.month_start = payload.month_start
              this.month_end = payload.month_end
              this.recalculation_details = []
              this.fill_loading = false
              this.tab = 0

              this.fetch_data_by_id()

              if (!this.itemId && !this.tax_id) {
                this.tax_id = this.settings.pdv_without?.value
              }

              if (!this.isNew) {
                this.checkDocument(false, false)
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  recalculationAPI.remove({ recalculation_id: this.itemId })
                    .then(response => response.data)
                    .then(data => {
                      if (data) {
                        this.closeModal({ operation: 'fetch' })
                      }
                    })
                    .catch(err => {
                      const error = err.response.data.detail;
                      this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    })
                }
              }
              if (payload.id === 'clear_recalculation_details') {
                if (payload.answer) {
                  this.clearRecalculationDetails()
                }
              }
              if (payload.id === 'fill_recalculation_details') {
                if (payload.answer) {
                  this.fetchTableData()
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  created() {
    this.fetchServices()
    this.fetchTaxSelect()
    this.fetchAccountingSettings()
  }
}
</script>
